// Place all the styles related to the clients controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.TTNormsPro-Regular { 
  font-family: TTNormsPro-Regular;
  font-weight: normal;
  font-style: normal;
}
.TTNormsPro-Medium { 
  font-family: TTNormsPro-Medium;
  font-weight: normal;
  font-style: normal;
}


.h1,.h2,.h3,.h4,.h5,.h6,.syhphototitle, label,
h1,h2,h3,h4,h5,h6{
     font-family: TTNormsPro-Regular, 'PT Sans',"Helvetica Neue", Helvetica,Arial,sans-serif;
} 

li.nostyle {
  list-style-type: none;
}

.nav>li>a {
    font-family: TTNormsPro-Regular;
}

a {
   color: #00a5ce;  
}

.panel-title {
    font-family: TTNormsPro-Medium;  
}

.faq-question {
    font-family: TTNormsPro-Medium;  
    font-size: 16px;
    color: #212529;
}

.faq-answer {
    font-family: TTNormsPro-Regular;  
    font-size: 14px;
    color: #212529;
}

.accordion-button {
    color: #212529;  
}

.accordion-button:not(.collapsed) {
    color: #212529;  
}

.col-form-label {
    font-family: TTNormsPro-Medium;  
    font-size: 14px;     
    text-align:right;
}

.form-control {
    font-size: 14px;    
}

.big-checkbox {
    width: 20px; 
    height: 20px;
}

.nav .caret {
    border-top-color: #95d9ea;
    border-bottom-color: #00a5ce;
}

.FISHnavbar-toggler .FISHnavbar-toggler-icon {
    background-color: #95d9ea;
    height: 3px;
    border: 1px solid #95d9ea;
}

.navbar-custom {
  border-radius: 0px;
  z-index: 16;
  box-shadow: 0 2px 3px 0 rgba(0,0,0, .4);
}

.navbar-toggle .icon-bar {
    background-color: #95d9ea;
    height: 3px;
    border: 1px solid #95d9ea;
}

.navbar-toggle {
    border: 1px solid #00a5ce;
}

.navbar-header{
    background:#00a5ce; 
    border-color:#00a5ce; 
    button {
       border: 1px solid #95d9ea;   
    }
}

td {
  .btn {
    margin: 0.25rem 0.125rem;    
  }
}

.navbar-brand {
  font-size: 2.0rem;
}

.syhnavbarstyle {
  border-radius: 5px 5px 5px 5px;
  background:#00a5ce; 
  border-color:#00a5ce; 
  a {
    color:#95d9ea; 
  }
  .dropdown-menu {
    background:#00a5ce; 
  }
  
  .dropdown-menu>li>a {
    color: #95d9ea;
    background:#00a5ce; 
    border-color:#00a5ce; 
    font-family: TTNormsPro-Regular;
    font-weight: bold;
  }  
  a {
     font-weight: bold;
     font-size: 16px;
     padding: 10px 5px 5px 10px;
  }
  button {
    border: 2px solid;
  }

}

.syhphototitle {
   color: #00a6cf;
   font-size: 18px;
}
.greytextbox {
  background:#eeeeee; 
  padding: 20px 40px 20px 40px;
  margin: 0px 0px 10px 0px;
  border-radius: 5px 5px 5px 5px;
  h1, h2, h4 {
    margin-top: 10px;
    color:#00a5ce ;    
  } 
  h3 {
    margin-top: 10px;
    color:#00a5ce ;    
    font-size: 24px;
  } 
  i {

  }
  .syhblock {
    padding: 15px 15px 5px 15px;
  }
}

.grey2textbox {
  font-size: 14px;     
  background:#eeeeee; 
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px 5px 5px 5px;
  h1, h2, h3, h4 {
    margin-top: 10px;
    color:#00a5ce ;  
  } 
  i {
    color:#00a5ce;
  }
  .syhblock {
    padding: 15px 15px 5px 15px;
  }
  .frontpageli {
    margin: 10px 0px 0px 0px;
  }
}
.syh1textbox {
  background:#00a5ce; 
  padding: 10px 20px 10px 20px;
  margin: 0px 0px 10px 0px;
  border-radius: 5px 5px 5px 5px;
  h1, h2, h3 {
    margin-top: 10px;
  } 
  color:#95d9ea; 
  a {
    color:#95d9ea; 
  }
  i {
    padding: 0px 10px 0px 0px;
  }
}
.syh1header {
  margin: 0px 0px 0px 0px;
  border-radius: 5px 5px 5px 5px;
  h1, h2, h3, h4 {
    font-weight: bold;
    margin-top: 10px;
  } 
  color:#00a5ce; 
  i {
    padding: 0px 10px 0px 0px;
  }
}

.syhrow {
    padding: 10px 0px 0px 0px;
}
.syhtopmargin {
    margin-top: 10px;
}

.syhleftcol {
    padding-right: 5px;
    padding-left: 15px;
}
.syhrightcol {
    padding-right:15px;
    padding-left: 5px;
}
.syh2textbox {
  background:#95d9ea; 
  padding: 20px 40px 20px 40px;
  margin: 0px 0px 10px 0px;
  border-radius: 5px 5px 5px 5px;
  h1, h2, h3, h4 {
    margin-top: 10px;
    color:#00a5ce ;
  } 
  a {
    color:#00a5ce; 
  }
}
.syh2btextbox {
  background:#95d9ea; 
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px 5px 5px 5px;
  h1, h2, h3, h4 {
    margin-top: 10px;
    color:#00a5ce ;
  } 
  a {
    color:#eeeeee; 
  }
}
.syh3textbox {
  background:#fbfafa; 
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px 5px 5px 5px;
  h1, h2, h3 {
    margin-top: 10px;
  } 
}
.footer {
  background:#00a5ce; 
  padding: 10px 20px 10px 30px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px 5px 5px 5px;
  h1, h2, h3 {
    margin-top: 10px;
  } 
  color:#95d9ea; 
  a {
    color: #eeeeee; 
    text-decoration: none;
  }
  i {
    color:#95d9ea; 
  }
  .breadcrumb {
    background-color: #00a5ce;
    padding: 20px 10px 0px 0px;
    margin-bottom: 0px;
    a {
      color:#95d9ea;      
    }
  }
  .stay-connected {
    padding: 0px 0px 0px 0px;    
  }
  .contact {
    a {
      padding: 0px 20px 0px 5px;   
      color:#95d9ea;  
    }
  }
}

